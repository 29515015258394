import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getCalendarSlots } from './thunks';
import { TimeHelper } from '../../utils';
import { Slot } from '../../types';
import { SyncSlotsPayload } from './types';

interface InitialState {
  loading: boolean;
  error: any;
  date?: string;
  data: {};
  lastTimestamp: number;
}

const initialState: InitialState = {
  loading: false,
  error: null,
  data: {},
  lastTimestamp: 0,
};

let latestTimestamp = 0;

const slice = createSlice({
  name: 'calendarSlots',
  initialState,
  reducers: {
    resetCalendarSlots: (state) => {
      // eslint-disable-next-line no-param-reassign,@typescript-eslint/no-unused-vars
      state = initialState;
    },
    syncSlots: (state, action: PayloadAction<SyncSlotsPayload>) => {
      const {
        data,
        timeZone,
      } = action.payload;

      const latestSet = data.set.reduce((prev, current) => (prev.timestamp > current.timestamp ? prev : current), {
        timestamp: latestTimestamp,
        items: [],
      });

      let newSlots = { ...state.data };

      // prevents from updating the state if the data is older than the latest one
      if (latestSet.timestamp > latestTimestamp) {
        newSlots = {};

        latestTimestamp = latestSet.timestamp;
        latestSet.items.forEach((slot) => {
          const {
            date,
            employeeId,
          } = slot;
          if (!newSlots[date]) {
            newSlots[date] = {};
          }
          if (!newSlots[date][employeeId]) {
            newSlots[date][employeeId] = [];
          }
          newSlots[date][employeeId].push({
            ...slot,
            time: TimeHelper.getTimeByMinutesWithTimeZone(slot.time, timeZone),
          });
        });
      }

      data.remove.forEach((deletedSlot) => {
        const {
          date,
          employeeId,
        } = deletedSlot;

        if (!newSlots[date]) {
          return;
        }
        if (!newSlots[date][employeeId]) {
          return;
        }
        newSlots[date][employeeId] = newSlots[date][employeeId].filter((slot) => slot.id !== deletedSlot.id);
      });

      state.data = newSlots;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCalendarSlots.pending, (state, action) => {
      state.loading = true;
      state.error = null;
      state.lastTimestamp = action.meta.arg.timestamp;
    });
    builder.addCase(getCalendarSlots.fulfilled, (state, action) => {
      if (state.lastTimestamp <= action.payload.timestamp) {
        state.loading = false;
        state.error = null;
        state.data = { ...action.payload.data.slots };
      }
    });
    builder.addCase(getCalendarSlots.rejected, (state, action) => {
      if (state.lastTimestamp <= action.payload!.timestamp) {
        state.loading = false;
        state.error = action.error; // todo check it
      }
    });
  },
});

export const {
  resetCalendarSlots,
  syncSlots,
} = slice.actions;
export default slice.reducer;
